import React, { useState } from 'react';
import mapboxSdk from '@mapbox/mapbox-sdk/services/geocoding';
import '../css/AddressAutosuggest.css'; // Import your CSS file for styling

const geocodingClient = mapboxSdk({ accessToken: 'pk.eyJ1IjoiYm9ubmljaW5hdGUiLCJhIjoiY2x3eWp4MnBrMW9vdjJwcTU1anhmY2hkZyJ9.EW5CcYxH2yYlCfqTt3zHZA' });

const AddressAutosuggest = () => {
  const [query, setQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState('');

  const handleInputChange = async (e) => {
    const inputValue = e.target.value;
    setQuery(inputValue);

    try {
      const response = await geocodingClient.forwardGeocode({
        query: inputValue,
        limit: 5
      }).send();

      setSuggestions(response.body.features);
    } catch (error) {
      console.error('Error fetching address suggestions:', error);
    }
  };

  const handleSuggestionClick = (address) => {
    setSelectedAddress(address);
    setQuery(address.place_name);
    setSuggestions([]);
  };

  return (
    <div className="autosuggest-container">
      <input
        type="text"
        placeholder="Enter address"
        value={query}
        onChange={handleInputChange}
      />
      {suggestions.length > 0 && (
        <ul className="suggestions-list">
          {suggestions.map((suggestion, index) => (
            <li key={index} onClick={() => handleSuggestionClick(suggestion)}>
              {suggestion.place_name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default AddressAutosuggest;