import React from 'react';
import styled from 'styled-components';
import oceanVideo from '../assets/vid/ocean.mp4'; // Ensure this path is correct
import fallbackImage from '../assets/img/fallback.png'; // Add a fallback image

const HomePageContainer = styled.div`
  font-family: 'Arial, sans-serif';
`;

const HeroSection = styled.section`
  position: relative;
  height: 100vh;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0;
  margin: 0;
  overflow: hidden;
  background: url(${fallbackImage}) no-repeat center center;
  background-size: cover;

  @media (min-width: 769px) {
    background: none; /* Remove background for larger screens */
  }
`;

const HeroText = styled.h1`
  font-size: 3rem;
  margin-bottom: 1rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const SubText = styled.p`
  font-size: 1.5rem;
  margin-bottom: 2rem;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const HeroButton = styled.a`
  background-color: #fff;
  color: #f0c040;
  padding: 0.75rem 1.5rem;
  border-radius: 25px;
  text-decoration: none;
  font-weight: bold;
  font-size: 1rem;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: #f0c040;
    color: #fff;
  }

  @media (max-width: 768px) {
    font-size: 0.875rem;
    padding: 0.5rem 1rem;
  }
`;

const VideoBackground = styled.video`
  display: none; /* Hide video by default */

  @media (min-width: 769px) {
    display: block; /* Display video for larger screens */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    margin: 0;
    padding: 0;
    pointer-events: none;
  }
`;

const Home = () => {
  return (
    <HomePageContainer>
      <HeroSection>
        <VideoBackground autoPlay loop muted playsInline>
          <source src={oceanVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </VideoBackground>
        <HeroText>Welcome to DyetWater.shop</HeroText>
        <SubText>Explore our products and make a splash!</SubText>
        <HeroButton href="/shop">Shop Now</HeroButton>
      </HeroSection>
    </HomePageContainer>
  );
};

export default Home;