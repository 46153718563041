import React from 'react';
import { Link } from 'react-router-dom';
import { FaBars, FaUser, FaShoppingCart } from 'react-icons/fa';
import '../css/Navbar.css'; // Import your CSS file for styling

const Navbar = ({ isLoggedIn, username, handleLogout }) => {
  const [showMenu, setShowMenu] = React.useState(false);
  const [cartItems, setCartItems] = React.useState(0);
  const [cartDropdownOpen, setCartDropdownOpen] = React.useState(false);

  const handleToggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const toggleCartDropdown = () => {
    setCartDropdownOpen(!cartDropdownOpen);
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <Link to="/" className="navbar-brand">
          DyetWater.shop
        </Link>
        <div className="navbar-toggle" onClick={handleToggleMenu}>
          <FaBars />
        </div>
        <ul className={showMenu ? "navbar-menu active" : "navbar-menu"}>
          <li className="navbar-item">
            <Link to="/" className="navbar-link">Home</Link>
          </li>
          <li className="navbar-item">
            <Link to="/shop" className="navbar-link">Shop</Link>
          </li>
          <li className="navbar-item">
            <Link to="/about" className="navbar-link">About</Link>
          </li>
        </ul>
        <div className="navbar-icons">
          <div className="navbar-icon cart-icon" onClick={toggleCartDropdown}>
            <FaShoppingCart />
            {cartItems > 0 && <span className="cart-count">{cartItems}</span>}
            <div className={cartDropdownOpen ? "cart-dropdown open" : "cart-dropdown"}>
              {cartItems > 0 ? (
                <p>Cart contents go here</p>
              ) : (
                <p>Your cart is empty</p>
              )}
            </div>
          </div>
          <div className="navbar-icon">
            {isLoggedIn ? (
              <Link to="/account" className="navbar-link">
                <FaUser />
              </Link>
            ) : (
              <Link to="/login" className="navbar-link">Login</Link>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
