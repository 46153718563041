import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../css/SigninPage.css';

const SigninPage = ({ handleLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Simulated authentication
    if (username === 'admin' && password === 'P@ssword1') {
      // Successful admin login
      handleLogin({ username, isAdmin: true }); // Call handleLogin with the username and isAdmin flag
      navigate('/account'); // Redirect to account page
    } else if (username === 'user' && password === 'password') {
      // Successful regular user login
      handleLogin({ username, isAdmin: false }); // Call handleLogin with the username and isAdmin flag
      navigate('/account'); // Redirect to account page
    } else {
      setError('Invalid username or password');
    }
  };

  return (
    <div className="signin-container">
      <div className="signin-form">
        <h2>Sign In</h2>
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <label htmlFor="username">Username</label>
            <input type="text" id="username" name="username" value={username} onChange={handleUsernameChange} />
          </div>
          <div className="input-group">
            <label htmlFor="password">Password</label>
            <input type="password" id="password" name="password" value={password} onChange={handlePasswordChange} />
          </div>
          {error && <div className="error-message">{error}</div>}
          <div className="btn-group">
            <button type="submit" className="signin-btn">Login</button>
          </div>
        </form>
        <div className="signin-footer">
          <p>Don't have an account? <Link to="/signup">Sign Up</Link></p>
          <p><Link to="/forgot-password">Forgot password?</Link></p>
        </div>
      </div>
    </div>
  );
};

export default SigninPage;
