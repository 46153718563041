import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../css/AccountPage.css'; // Import your CSS file for styling

const AccountPage = ({ username, handleLogout, isAdmin }) => {
  const navigate = useNavigate();

  const logout = () => {
    handleLogout();
    navigate('/');
  };

  return (
    <div className="account-page">
      <div className="side-nav">
        <Link to="#profile" className="side-nav-link">Profile</Link>
        <Link to="#orders" className="side-nav-link">Orders</Link>
        {isAdmin && <Link to="/admin" className="side-nav-link">Admin Page</Link>}
        <button onClick={logout} className="side-nav-link logout-btn">Logout</button>
      </div>
      <div className="main-content">
        <section id="profile" className="profile-section">
          <h2>Profile</h2>
          <p><strong>Full Name:</strong></p>
          <p><strong>Username:</strong> {username}</p>
          <p><strong>Address:</strong> (To be loaded from database)</p>

          <button className="btn">Change Password</button>
        </section>
        <section id="orders" className="orders-section">
          <h2>Orders</h2>
          <p>Order details and statuses will be displayed here (to be loaded from database).</p>
        </section>
      </div>
    </div>
  );
};

export default AccountPage;
