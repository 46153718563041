import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import './App.css';
import HomePage from './components/HomePage';
import ShopPage from './components/ShopPage';
import AboutPage from './components/AboutPage';
import AccountPage from './components/AccountPage';
import SignupPage from './components/SignupPage';
import SigninPage from './components/SigninPage';
import AdminPage from './components/AdminPage';
import ProtectedRoute from './components/ProtectedRoute'; // Ensure correct import path

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      setIsLoggedIn(true);
      setUsername(parsedUser.username);
      setIsAdmin(parsedUser.isAdmin); // Assume user object contains isAdmin flag
    }
  }, []);

  const handleLogin = (user) => {
    setIsLoggedIn(true);
    setUsername(user.username);
    setIsAdmin(user.isAdmin); // Assume user object contains isAdmin flag
    console.log(user)
    localStorage.setItem('user', JSON.stringify(user));
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    setUsername('');
    setIsAdmin(false);
    localStorage.removeItem('user');
  };

  return (
    <Router>
      <div>
        <Navbar isLoggedIn={isLoggedIn} username={username} handleLogout={handleLogout} />
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route path="/shop" element={<ShopPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/account" element={
            <ProtectedRoute isLoggedIn={isLoggedIn}>
              <AccountPage username={username} handleLogout={handleLogout} isAdmin={isAdmin} />
            </ProtectedRoute>
          } />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/login" element={<SigninPage handleLogin={handleLogin} />} />
          <Route path="/admin" element={
            <ProtectedRoute isLoggedIn={isLoggedIn} isAdmin={isAdmin} adminOnly={true}>
              <AdminPage />
            </ProtectedRoute>
          } />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
