import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import AddressAutosuggest from './AddressAutosuggest'; // Import the AddressAutosuggest component
import styled from 'styled-components';

const SignupContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const SignupForm = styled.div`
  background-color: #f9f9f9;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const FormTitle = styled.h2`
  margin-bottom: 20px;
`;

const FormInput = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
`;

const ErrorMessage = styled.p`
  color: #ff3333;
  margin-top: -10px;
  margin-bottom: 20px;
`;

const SubmitButton = styled.button`
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }
`;

const SignupFooter = styled.div`
  text-align: center;
  margin-top: 20px;
`;

const SignupPage = () => {
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [passwordValid, setPasswordValid] = useState(false);
  const [email, setEmail] = useState('');
  const [repeatEmail, setRepeatEmail] = useState('');
  const [emailMatch, setEmailMatch] = useState(true);

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    setPasswordValid(validatePassword(newPassword));
  };

  const handleRepeatPasswordChange = (e) => {
    setRepeatPassword(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailMatch(e.target.value === repeatEmail);
  };

  const handleRepeatEmailChange = (e) => {
    setRepeatEmail(e.target.value);
    setEmailMatch(e.target.value === email);
  };

  const validatePassword = (password) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return regex.test(password);
  };

  return (
    <SignupContainer>
      <SignupForm>
        <FormTitle>Sign Up</FormTitle>
        <form>
          <FormInput type="text" placeholder="Full Name" required />
          <FormInput type="text" placeholder="Username" required />
          <FormInput type="password" placeholder="Password" value={password} onChange={handlePasswordChange} required />
          {password && !passwordValid && (
            <ErrorMessage>Password must contain at least 8 characters, including uppercase, lowercase, number, and special character</ErrorMessage>
          )}
          <FormInput type="password" placeholder="Repeat Password" value={repeatPassword} onChange={handleRepeatPasswordChange} required />
          {repeatPassword && password !== repeatPassword && (
            <ErrorMessage>Passwords do not match</ErrorMessage>
          )}
          <FormInput type="email" placeholder="Email" value={email} onChange={handleEmailChange} required />
          <FormInput type="email" placeholder="Repeat Email" value={repeatEmail} onChange={handleRepeatEmailChange} required />
          {!emailMatch && (
            <ErrorMessage>Email addresses do not match</ErrorMessage>
          )}
          <div className="input-group">
            <label htmlFor="address">Address</label>
            <AddressAutosuggest /> {/* Include the AddressAutosuggest component */}
          </div>
          <SubmitButton type="submit">Sign Up</SubmitButton>
        </form>
        <SignupFooter>
          <p>Already have an account? <Link to="/login">Sign In</Link></p>
        </SignupFooter>
      </SignupForm>
    </SignupContainer>
  );
};

export default SignupPage;